import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash.kebabcase';

import { ReactComponent as ArrowDownIcon } from '~assets/icons/arrow-down.svg';
import { ReactComponent as ArrowLeftIcon } from '~assets/icons/arrow-left.svg';
import { ReactComponent as ArrowrightIcon } from '~assets/icons/arrow-right.svg';
import { ReactComponent as ArrowUpIcon } from '~assets/icons/arrow-up.svg';
import { ReactComponent as CaretDownIcon } from '~assets/icons/caret-down.svg';
import { ReactComponent as CaretLeftIcon } from '~assets/icons/caret-left.svg';
import { ReactComponent as CaretrightIcon } from '~assets/icons/caret-right.svg';
import { ReactComponent as CaretUpIcon } from '~assets/icons/caret-up.svg';
import { ReactComponent as CheckIcon } from '~assets/icons/check.svg';
import { ReactComponent as ChevronDownIcon } from '~assets/icons/chevron-down.svg';
import { ReactComponent as ChevronLeftIcon } from '~assets/icons/chevron-left.svg';
import { ReactComponent as ChevronrightIcon } from '~assets/icons/chevron-right.svg';
import { ReactComponent as ChevronUpIcon } from '~assets/icons/chevron-up.svg';
import { ReactComponent as CrossIcon } from '~assets/icons/cross.svg';
import { ReactComponent as EnvelopeIcon } from '~assets/icons/envelope.svg';
import { ReactComponent as FacebookIcon } from '~assets/icons/facebook.svg';
import { ReactComponent as HomeIcon } from '~assets/icons/home.svg';
import { ReactComponent as IndicatorOffIcon } from '~assets/icons/indicator-off.svg';
import { ReactComponent as IndicatorOnIcon } from '~assets/icons/indicator-on.svg';
import { ReactComponent as LinkedinIcon } from '~assets/icons/linkedin.svg';
import { ReactComponent as LocationIcon } from '~assets/icons/location.svg';
import { ReactComponent as MinusIcon } from '~assets/icons/minus.svg';
import { ReactComponent as PauseIcon } from '~assets/icons/pause.svg';
import { ReactComponent as PhoneIcon } from '~assets/icons/phone.svg';
import { ReactComponent as PlayIcon } from '~assets/icons/play.svg';
import { ReactComponent as PlusIcon } from '~assets/icons/plus.svg';
import { ReactComponent as QuoteMarksIcon } from '~assets/icons/quote-marks.svg';
import { ReactComponent as SearchIcon } from '~assets/icons/search.svg';
import { ReactComponent as TwitterIcon } from '~assets/icons/twitter.svg';
import { ReactComponent as LogoIcon } from '~assets/icons/logo.svg';
import { ReactComponent as SpeechBubbleIcon } from '~assets/icons/speech-bubble.svg';
import { ReactComponent as CheckBox } from '~assets/icons/check-box.svg';
import { ReactComponent as CheckBoxOutline } from '~assets/icons/check-box-outline.svg';
import { ReactComponent as MoreHorizontal } from '~assets/icons/more-horizontal.svg';

export const allIconComponents = {
    'arrow-down': ArrowDownIcon,
    'arrow-left': ArrowLeftIcon,
    'arrow-right': ArrowrightIcon,
    'arrow-up': ArrowUpIcon,
    'caret-down': CaretDownIcon,
    'caret-left': CaretLeftIcon,
    'caret-right': CaretrightIcon,
    'caret-up': CaretUpIcon,
    'check': CheckIcon,
    'chevron-down': ChevronDownIcon,
    'chevron-left': ChevronLeftIcon,
    'chevron-right': ChevronrightIcon,
    'chevron-up': ChevronUpIcon,
    'cross': CrossIcon,
    'envelope': EnvelopeIcon,
    'facebook': FacebookIcon,
    'home': HomeIcon,
    'indicator-off': IndicatorOffIcon,
    'indicator-on': IndicatorOnIcon,
    'linkedin': LinkedinIcon,
    'location': LocationIcon,
    'minus': MinusIcon,
    'pause': PauseIcon,
    'phone': PhoneIcon,
    'play': PlayIcon,
    'plus': PlusIcon,
    'quote-marks': QuoteMarksIcon,
    'search': SearchIcon,
    'twitter': TwitterIcon,
    'logo': LogoIcon,
    'speech-bubble': SpeechBubbleIcon,
    'check-box-outline': CheckBoxOutline,
    'check-box': CheckBox,
    'more-horizontal': MoreHorizontal
};

const Icon = props => {
    const SpecificIcon = allIconComponents[kebabCase(props.icon)];

    return <SpecificIcon {...props} />;
};

export default Icon;

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.string
};
